<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">
                <div class="MenuBox">
                    <div class="m1dep">공통코드 관리</div>
                    <div class="m2dep">
                        <ul>
                            <li class="over" @click="$router.push({name:'ADMCODE01M01'})">공통 분류코드 등록/수정</li>
                            <li class="over" @click="$router.push({name:'ADMCODE02M01'})">공통 코드 등록/수정</li>
                            <li class="on">공통 상세코드 등록/수정</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="contents_body">
                <div class="Board">
                    <table class="Board_type3">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>	
                            <tr v-if="isModify">
                                <th>업무중분류코드</th>
                                <td>
                                    <input type="text" v-model="data.codeId" :readonly="true">
                                </td>
                            </tr>
                            <tr v-if="!isModify">
                                <th>업무분류코드명</th>
                                <td>
                                    <div class="select_wrap">
                                        <select v-model="data.clCode"> 
                                            <option value="" label="선택하세요"/>
                                            <option v-for="option in clCodeList" :key="option.clCode" :value="option.clCode">
                                                {{option.clCodeNm}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="select_wrap">
                                        <select v-model="data.codeId"> 
                                            <option value="" label="선택하세요"/>
                                            <template v-for="option in codeList">
                                                <option v-if="option.clCode == data.clCode" :key="option.codeId"  :value="option.codeId">
                                                    {{option.codeIdNm}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="isModify">
                                <th>상세코드</th>
                                <td>
                                    <input type="text" v-model="data.code" :readonly="true">
                                </td>
                            </tr>
                            <tr v-if="!isModify">
                                <th>상세코드</th>
                                <td>
                                    <input type="text" v-model="data.code" maxlength="15">
                                </td>
                            </tr>
                            <tr>
                                <th>상세코드명</th>
                                <td><input type="text" v-model="data.codeNm" maxlength="60"></td>
                            </tr>
                            <tr>
                                <th>상세코드설명</th>
                                <td><input type="text" v-model="data.codeDc" maxlength="200"></td>
                            </tr>
                            <tr>
                                <th>사용여부</th>
                                <td>
                                    <div class="select_wrap">
                                        <select v-model="data.useAt"> 
                                            <option value="Y" label="예"/>
                                            <option value="N" label="아니요"/>									
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--버튼영역-->
                <div class="BtnArea">
                    <div class="Btninner">								
                        <span class="btn write" @click="btnMod" v-if="isModify" >수정</span>
                        <span class="btn list" @click="btnListMod" v-if="isModify">목록</span>
                        <span class="btn write" @click="btnReg" v-if="!isModify">등록</span>
                        <span class="btn list" @click="btnListReg" v-if="!isModify">목록</span>
                    </div>
                </div>
                <!--//버튼영역-->
            </div><!--//contents_body-->
        </div><!--//contents-->
    </div><!--//container-->
</template>

<script>

export default {
    data() {
        return {
            data : {
                clCode : '',
                codeId : '',
                useAt : 'Y'
            },
            isModify : false,

            clCodeList: [],
            codeList: [],
        }
    },

    beforeCreate() {
      // console.log('beforeCreate');
    },
    created() {
      // console.log('created');
    },
    beforeMount() {
      // console.log('beforeMount');
    },
    mounted() {
      // console.log('mounted');

        if(this.$route.params.code != null && this.$route.params.code != '') {
            this.isModify = true;
        }

        this.getClCodeList();
        this.getDetail();
    },
    beforeUpdate() {
      // console.log('beforeUpdate');
    },
    updated() {
    //   console.log('update');
    },
    beforeDestroy() {
      // console.log('beforeDestroy');
    },
    destroyed() {
      // console.log('destroyed');
    },    

    methods : {
        
        // 전체 공통코드 조회해서 clCodeList만 사용
        getClCodeList() {
        
            this.$.httpPost("/api/code/getCommonCode")
                .then((res) => {
                    // console.log(res.data);
                    
                    this.clCodeList = res.data.clCode;
                    this.codeList = res.data.code;
                })
                .catch((err) => {
                    // console.log("=============================");
                    // console.log(err);
                    // console.log(err.response);
                    // console.log("=============================");
                    alert(err
                        .response
                        .data
                        .error_description);
                });
        },
        
        getDetail() {

            // 수정일때만 상세 조회
            if(this.$route.params.codeId != null && this.$route.params.codeId != ''
                && this.$route.params.code != null && this.$route.params.code != ''
                ) {
                
                var param = {
                    codeId: this.$route.params.codeId,
                    code: this.$route.params.code
                };
                
                this.$.httpPost('/api/code/adm/getDetailCodeDetail', param)
                    .then(res => {
                        //console.log(res.data);
						this.data = res.data;
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            }
        },

        btnReg() {

            // console.log(this.data);

            this.$.httpPost('/api/code/adm/regDetailCode', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push({name:'ADMCODE03M01'});
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        btnMod() {
            // console.log(this.data);

            this.$.httpPost('/api/code/adm/modDetailCode', this.data)
                .then(() => {
                    // console.log(res.data);
                    this.$router.push({name:'ADMCODE03M01'});
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 

                    alert(err.response.data.error_description);
                });
        },

        // 수정일때 목록 버튼 클릭
        btnListMod() {
            this.$router.go(-2);
        },

        // 등록일때 목록 버튼 클릭
        btnListReg() {
            this.$router.go(-1);
        }
       
    }

}
</script>
